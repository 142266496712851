import { useRouter } from 'next/router';
import type {} from '@redux-devtools/extension';
import { ParsedUrlQuery } from 'querystring'; // required for devtools typing
import { SortOrderValues } from '@/types/sort';
import { EntityType } from '@/types/entity';
import { getLocalStorage } from '@/utilities/localStorage';

type BrowseType = 'folder' | 'album' | 'asset';
type ManageType = 'metadata' | 'variants' | 'tags' | 'users' | 'workflows' | 'destinations';
type UiSection = 'browse' | 'search' | 'trash' | 'manage' | 'uploads' | 'duplicates';

interface RouteState {
  pathSlugs?: Array<string> | null;
  currentBrowseType?: BrowseType | null;
  currentBrowseId?: string | null;
  currentManageType?: ManageType | null;
  uiSection: UiSection;
}

const isValidManageType = (type: string): type is ManageType => {
  return ['metadata', 'variants', 'tags', 'users', 'workflows', 'destinations'].includes(type);
};

const parseRoute = (path: string): RouteState => {
  // Default
  if (!path) {
    return { uiSection: 'browse', currentBrowseType: 'folder' };
  }

  const cleanPath = path.split('?')[0];
  const segments = cleanPath.split('/').filter(Boolean);

  // Default
  if (segments.length === 0) {
    return { uiSection: 'browse', currentBrowseType: 'folder' };
  }

  const [section, ...rest] = segments;
  switch (section) {
    case 'browse':
      if (rest.length > 0) {
        return {
          uiSection: 'browse',
          pathSlugs: rest,
        };
      }
    case 'assets':
      return {
        uiSection: 'browse',
        currentBrowseType: 'asset',
      };

    case 'search':
      return {
        uiSection: 'search',
        pathSlugs: rest,
      };

    case 'trash':
      return {
        uiSection: 'trash',
        pathSlugs: rest,
      };

    case 'manage':
      const manageId = rest[1] || null;
      return {
        pathSlugs: rest,
        uiSection: 'manage',
        currentManageType: isValidManageType(rest[0]) ? rest[0] : null,
        currentBrowseId: rest[1] ?? null,
      };

    case 'uploads':
      return {
        pathSlugs: rest,
        uiSection: 'uploads',
      };
    case 'duplicates':
      return {
        uiSection: 'duplicates',
        pathSlugs: rest,
      };

    default:
      return {
        currentManageType: undefined,
        uiSection: 'browse',
        currentBrowseType: 'folder',
        currentBrowseId: null,
        pathSlugs: rest,
      };
  }
};

export const publicPathPrefixes = ['/shares'];

export const useCurrentPage = () => {
  const path = useRouter().asPath;

  const { uiSection, currentManageType, pathSlugs } = parseRoute(path);
  const { pathname } = useRouter();
  const { query, push } = useRouter();

  const isPublicRoute = publicPathPrefixes.some((prefix) => pathname?.startsWith(prefix));

  const browseRedirection = (parentPathArray: Array<string> | null) => {
    if (parentPathArray === null) {
      void push('/browse');
    }

    // eslint-disable-next-line prefer-const
    let parentPath = parentPathArray?.join('/');
    const removeSlugFromQuery = (queryParam: ParsedUrlQuery) => {
      const { slug, ...restOfQuery } = queryParam; // Destructure to remove 'slug'
      return { ...restOfQuery };
    };
    void push({
      pathname: `/browse/${parentPath}`, // Use the combined path string
      query: {
        ...removeSlugFromQuery(query),
        sortValue: SortOrderValues.CREATED_AT,
        pageIndex: 1,
        pageSize: getLocalStorage(`${EntityType.Asset}/datatable/pagination/pageSize`),
      },
    });
  };

  return {
    isHomePage: uiSection === 'browse',
    isTrashPage: uiSection === 'trash',
    isUploadPage: uiSection === 'uploads',
    isSearchPage: uiSection === 'search',
    isManageWelcomePage: uiSection === 'manage' && currentManageType === null,
    isTagsPage: uiSection === 'manage' && currentManageType === 'tags',
    isUsersPage: uiSection === 'manage' && currentManageType === 'users',
    isManagePage: uiSection === 'manage',
    isDuplicatesPage: uiSection === 'duplicates',
    uiSection: uiSection,
    pathSlugs: pathSlugs,
    isPublicRoute,
    browseRedirection,
  };
};
