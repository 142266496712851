import { PropsWithChildren, createContext, useContext, useState, Dispatch, SetStateAction } from 'react';
import { Asset, AssetIdNameTypes } from '@/types/asset';
import { uniq } from 'lodash';

export type AssetSelectionTypes = AssetIdNameTypes & {
  assets: Array<Asset>;
  index: number;
  metaKey?: boolean;
  shiftKey?: boolean;
  ctrlKey?: boolean;
  isAllSelected?: boolean;
};

type AssetSelectionContextType = {
  selectedAssetIds: Array<AssetIdNameTypes>;
  toggleAssetSelection: ({ id, name, assets, index, metaKey, shiftKey }: AssetSelectionTypes) => void;
  selectAllAssets: (allAssetIds: Array<AssetIdNameTypes>) => void;
  deselectAllAssets: () => void;
  contextAssetSelection: ({ id, name }: AssetIdNameTypes) => void;
  setSelectedAssetIds: Dispatch<SetStateAction<Array<AssetIdNameTypes>>>;
};

const AssetSelectionContext = createContext<AssetSelectionContextType | undefined>(undefined);

export function AssetSelectionProvider({ children }: PropsWithChildren) {
  const [selectedAssetIds, setSelectedAssetIds] = useState<Array<AssetIdNameTypes>>([]);
  const [lastSelectedIndex, setLastSelectedIndex] = useState(-1);

  const contextAssetSelection = ({ id, name }: AssetIdNameTypes) => {
    if (!selectedAssetIds.some(({ id: assetId }) => assetId === id)) {
      setSelectedAssetIds([{ id, name }]);
    }
  };
  const toggleAssetSelection = ({
    id,
    name,
    assets,
    index,
    metaKey,
    shiftKey,
    ctrlKey,
    isAllSelected,
  }: AssetSelectionTypes) => {
    let currentSelectedIds = [...selectedAssetIds];
    if (isAllSelected) {
      currentSelectedIds = [];
    }
    let newSelectedAssetIds: Array<{ id: string; name: string }>;
    const newLastSelectedIndex = index;
    if (!metaKey && !shiftKey && !ctrlKey) {
      if (currentSelectedIds.some(({ id: assetId }) => assetId === id)) {
        newSelectedAssetIds = currentSelectedIds.filter(({ id: assetId }) => assetId !== id);
      } else {
        newSelectedAssetIds = [{ id, name }];
      }
    } else if (shiftKey) {
      if (lastSelectedIndex >= index) {
        const selectedOnes = assets?.slice(index, lastSelectedIndex).map(({ id, name }) => ({ id, name }));
        newSelectedAssetIds = currentSelectedIds.concat(selectedOnes);
      } else {
        const selectedOnes = assets.slice(lastSelectedIndex + 1, index + 1).map(({ id, name }) => ({ id, name }));
        newSelectedAssetIds = currentSelectedIds.concat(selectedOnes);
      }
    } else if (metaKey || ctrlKey) {
      const foundIndex = currentSelectedIds.findIndex((f) => f.id === id);
      // If found remove it to unselect it.
      if (foundIndex >= 0) {
        newSelectedAssetIds = [...currentSelectedIds.slice(0, foundIndex), ...currentSelectedIds.slice(foundIndex + 1)];
      } else {
        newSelectedAssetIds = [...currentSelectedIds, { id, name }];
      }
    }

    const finalList = assets
      ? assets.filter((f) => uniq(newSelectedAssetIds).find((a) => a.id === f.id)).map(({ id, name }) => ({ id, name }))
      : [];

    setSelectedAssetIds(finalList);
    setLastSelectedIndex(newLastSelectedIndex);
  };

  const selectAllAssets = (allAssetIds: Array<{ id: string; name: string }>) => {
    setSelectedAssetIds(allAssetIds);
  };

  const deselectAllAssets = () => {
    setSelectedAssetIds([]);
  };

  return (
    <AssetSelectionContext.Provider
      value={{
        selectedAssetIds,
        toggleAssetSelection,
        selectAllAssets,
        deselectAllAssets,
        contextAssetSelection,
        setSelectedAssetIds,
      }}
    >
      {children}
    </AssetSelectionContext.Provider>
  );
}

export function useAssetSelection() {
  const context = useContext(AssetSelectionContext);
  if (context === undefined) {
    throw new Error('useAssetSelection must be used within an AssetSelectionProvider');
  }
  return context;
}
