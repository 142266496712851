import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: Array<ClassValue>) {
  return twMerge(clsx(inputs));
}

export function capitalizeFileExtension(fileName: string): string {
  // Extract the extension
  const lastDotIndex = fileName.lastIndexOf('.');

  // If there's no extension, return the original file name
  if (lastDotIndex === -1) {
    return fileName;
  }

  // Get the file name and extension
  const namePart = fileName.substring(0, lastDotIndex);
  const extension = fileName.substring(lastDotIndex + 1);

  // Capitalize the extension and rebuild the file name
  const capitalizedExtension = extension.toUpperCase();
  return `${namePart}.${capitalizedExtension}`;
}
