type NotFoundProps = {
  title: string;
  icon: React.ReactElement;
};

export const NotFound = ({ title, icon }: NotFoundProps) => {
  return (
    <div className="flex min-h-[100vh] items-center justify-center bg-neutral-950 text-white">
      <div className="flex flex-col items-center gap-5 rounded-lg bg-yellow-500/10 p-10">
        {icon}
        {title}
      </div>
    </div>
  );
};
