import '@/styles/globals.css';
import type { AppProps } from 'next/app';
import Router from 'next/router';
import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { AssetSelectionProvider } from '@/context/AssetSelectionContext';
import { TreeContextProvider } from '@/context/TreeContext';
import { CommandContextProvider } from '@/context/CommandContext';
import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { Toaster } from '@/components/ui/toaster';
import { TooltipProvider } from '@/components/ui/tooltip';
import { ThemeProvider } from 'next-themes';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { DialogProvider } from '@/context/DialogContext';
import { HistoryProvider } from '@/context/HistoryContext';
import { UserSettingsProvider } from '@/context/UserSettingsContext';
import { toast } from '@/components/ui/use-toast';
import { errorProd, log } from '@/utilities/log';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AlgoliaProvider } from '@/context/AlgoliaContext';
import { useAuth0 } from '@auth0/auth0-react';
import { AlertTriangle, Loader2 } from 'lucide-react';
import { DataTableSelectionProvider } from '@/context/DataTableSelectionContext';
import { ModalContextProvider } from '@/context/ModalContext';
import { datadogRum } from '@datadog/browser-rum';
import { GlobalErrorProvider, useGlobalError } from '@/context/GlobalErrorContext';
import { useCurrentPage } from '@/hooks/useCurrentPage';
import { NotFound } from '@/components/not-found';
import { TenantData } from '@/types/tenant';
import { INTERNAL_V1 } from '@/hooks/damRequest';
import { DragAndDropProvider } from '@/context/DragAndDropContext';

if (process.env.NODE_ENV === 'production') {
  datadogRum.init({
    applicationId: '2892ff55-6be3-4ced-bc98-bbd922e214d5',
    clientToken: 'pubc3be883b4db3f3f8820d959f3842dda5',
    site: 'datadoghq.com',
    service: 'dam-frontend',
    env: process.env.NEXT_PUBLIC_ENVIRONMENT,
    version: process.env.NEXT_PUBLIC_VERSION,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingUrls: [(url) => url.startsWith(location.origin)],
  });
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: { refetchOnWindowFocus: false, refetchOnMount: false, retry: false },
    mutations: {
      onError: (error) => {
        errorProd(error);
        toast({
          title: 'Mutation Error',
          description: error.message,
        });
      },
    },
  },
  queryCache: new QueryCache({
    onError: (error) => {
      errorProd(error);
      toast({
        title: 'Query Error',
        description: error.message,
      });
    },
  }),
});

const Loading = () => (
  <div className="flex min-h-[100vh] items-center justify-center bg-white dark:bg-neutral-950">
    <div className="flex items-center justify-center gap-2">
      <Loader2 className="animate-spin stroke-neutral-950 dark:stroke-white" />
      Loading...
    </div>
  </div>
);

const GlobalErrorHandler: FC<PropsWithChildren> = ({ children }) => {
  const { errorCode } = useGlobalError();

  return errorCode === 401 ? (
    <div className="flex min-h-[100vh] items-center justify-center  bg-white text-black dark:bg-neutral-950 dark:text-white">
      <div className="flex flex-col items-center gap-5 rounded-lg bg-yellow-400/20 p-10 dark:bg-yellow-500/10">
        <AlertTriangle className="mr-2 size-12 stroke-yellow-400 dark:stroke-yellow-500" strokeWidth={1} />
        Unauthorized access
      </div>
    </div>
  ) : (
    children
  );
};

export default function App({ Component, pageProps }: AppProps) {
  if (
    !process.env.NEXT_PUBLIC_AUTH_DOMAIN ||
    !process.env.NEXT_PUBLIC_AUTH_CLIENT_ID ||
    !process.env.NEXT_PUBLIC_AUTH_ORGANIZATION_ID
  ) {
    throw new Error('Auth0 variables are not set');
  }

  const [tenantData, setTenantData] = useState<TenantData | null>(null);
  const [loading, setLoading] = useState(true);
  const [redirecting, setRedirecting] = useState(false);
  const { isPublicRoute } = useCurrentPage();

  useEffect(() => {
    const { pathname, search, hostname, protocol } = window.location;
    if (pathname === '/login') {
      setRedirecting(true);
      const urlParams = new URLSearchParams(search);
      const organizationName = urlParams.get('organization_name');
      const redirectUrl = process.env.NODE_ENV === 'development' ? 'localhost:8001' : hostname;

      const url = `https://${process.env.NEXT_PUBLIC_AUTH_DOMAIN}/authorize${search}&response_type=code&client_id=${process.env.NEXT_PUBLIC_AUTH_CLIENT_ID}&redirect_uri=${protocol}//${organizationName}.${redirectUrl}`;
      void Router.replace(url);
    }
  }, []);

  useEffect(() => {
    const fetchTenantData = async () => {
      const hostname = window.location.hostname;

      try {
        const initUrl =
          process.env.NODE_ENV === 'development' ? `http://${hostname}:3001${INTERNAL_V1}/init` : `${INTERNAL_V1}/init`;

        const res = await fetch(initUrl);

        if (!res.ok) {
          throw new Error(res.statusText);
        }

        const siteConfig: TenantData = await res.json();
        setTenantData(siteConfig);
        setLoading(false);
      } catch (err) {
        log('Failed to fetch tenant data:', err);
        setLoading(false);
      }
    };

    void fetchTenantData();
  }, []);

  if (loading) {
    return <Loading />;
  }

  if (redirecting) {
    return (
      <div className="flex min-h-[100vh] items-center justify-center  bg-neutral-950 text-white">
        <div className="flex flex-col items-center gap-5 rounded-lg bg-yellow-500/10 p-10">Redirecting...</div>
      </div>
    );
  }

  if (!tenantData) {
    return (
      <NotFound
        title="Tenant doesn't exist"
        icon={<AlertTriangle className="mr-2 size-12 stroke-yellow-500" strokeWidth={1} />}
      />
    );
  }

  return isPublicRoute ? (
    <ThemeProvider attribute="class" defaultTheme="system" enableSystem>
      <QueryClientProvider client={queryClient}>
        <UserSettingsProvider>
          <DialogProvider>
            <HistoryProvider>
              <TooltipProvider delayDuration={100}>
                <DragAndDropProvider>
                  <AssetSelectionProvider>
                    <DataTableSelectionProvider>
                      <CommandContextProvider>
                        <Component {...pageProps} tenant={tenantData.tenant} />
                      </CommandContextProvider>
                      <Toaster />
                      <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" />
                    </DataTableSelectionProvider>
                  </AssetSelectionProvider>
                </DragAndDropProvider>
              </TooltipProvider>
            </HistoryProvider>
          </DialogProvider>
        </UserSettingsProvider>
      </QueryClientProvider>
      <div id="portal" />
    </ThemeProvider>
  ) : (
    <Auth0Provider
      domain={process.env.NEXT_PUBLIC_AUTH_DOMAIN}
      clientId={process.env.NEXT_PUBLIC_AUTH_CLIENT_ID}
      onRedirectCallback={(appState?: AppState) => {
        void Router.replace(appState?.returnTo ?? '/');
      }}
      authorizationParams={{
        audience: 'https://internal.api.dam.lookbooks.com',
        organization: tenantData.tenant.auth0.organizationId,
        redirect_uri: typeof window === 'undefined' ? undefined : window.location.origin,
      }}
      cacheLocation="localstorage"
    >
      <GlobalErrorProvider>
        <ThemeProvider attribute="class" defaultTheme="system" enableSystem>
          <QueryClientProvider client={queryClient}>
            <AuthWall>
              <UserSettingsProvider>
                <ModalContextProvider>
                  <DialogProvider>
                    <HistoryProvider>
                      <TooltipProvider delayDuration={100}>
                        <AssetSelectionProvider>
                          <DataTableSelectionProvider>
                            <TreeContextProvider>
                              <AlgoliaProvider>
                                <DragAndDropProvider>
                                  {/* <DndProvider backend={HTML5Backend}> */}
                                  <CommandContextProvider>
                                    <GlobalErrorHandler>
                                      <Component {...pageProps} tenant={tenantData.tenant} />
                                    </GlobalErrorHandler>
                                  </CommandContextProvider>
                                  {/* </DndProvider> */}
                                </DragAndDropProvider>
                                <Toaster />
                                <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" />
                              </AlgoliaProvider>
                            </TreeContextProvider>
                          </DataTableSelectionProvider>
                        </AssetSelectionProvider>
                      </TooltipProvider>
                    </HistoryProvider>
                  </DialogProvider>
                </ModalContextProvider>
              </UserSettingsProvider>
              <div id="portal" />
            </AuthWall>
          </QueryClientProvider>
        </ThemeProvider>
      </GlobalErrorProvider>
    </Auth0Provider>
  );
}

const AuthWall: FC<PropsWithChildren> = ({ children }) => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      void loginWithRedirect();
    }
  }, [isAuthenticated, isLoading, loginWithRedirect]);

  if (isLoading || !isAuthenticated) {
    return <Loading />;
  }

  return children;
};
